<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <mindfuli-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('error404.title') }}
        </h2>
        <p class="mb-2">
          {{ $t('error404.subtitle') }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/home' }"
        >
          Back to home
        </b-button>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, } from 'bootstrap-vue'
import MindfuliLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    MindfuliLogo,
    BLink,
    BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
}
</script>

<style lang="scss">
@import './src/@core/scss/vue/pages/page-misc.scss';
</style>
